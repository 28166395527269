const authGateway = 'soma-dev-api-auth.azurewebsites.net';
const wlGateway = 'wl-soma-dev-api-participant.azurewebsites.net';

export const GATEWAY_SOMA_WL = `https://${wlGateway}/v1`;
export const GATEWAY_SOMA_WL_V3 = `https://${wlGateway}/v3`;
export const GATEWAY_SOMA_AUTH = `https://${authGateway}/v1`;

export const environment = {
    production: false,
    ENV_NAME: 'dev',
    TOKEN_BASE: `${GATEWAY_SOMA_AUTH}/tokens`,
    DELETE_ACCOUNT: `${GATEWAY_SOMA_AUTH}/profiles`,
    FORGOT_BASE: `${GATEWAY_SOMA_WL_V3}/login`,
    TENANT_BASE: `${GATEWAY_SOMA_WL}/tenants`,

    auth_params: {
        siteKey: '6LdY_LIcAAAAANz3LmOcb09cr2AuFCujcEj8dvcB',
        xConsumerId: 'AAAB7EC3-C219-4E29-BED7-7522CC2138C0',
        consumerToken: 'E02DE822-C8B5-4460-BD4D-C286E2AE1262',
    }
};
